import React from 'react'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { setLightboxForm } from '../actions'
import Row from '../components/page-content-row'
import HouseSeriesRow from '../components/house-series-row'
import HouseSeriesRowLinkItem from '../components/house-series-row/house-series-row-link-item'
import HalfGridRow from '../components/half-grid-row'

import PageDescription from '../components/page-description'
import HalfGridRowItem from '../components/half-grid-row/half-grid-row-item'
import HausSeriesLink1Svg from '../components/svg/haus-series-link/haus-series-1'
import HausSeries2 from '../components/svg/haus-series-link/haus-series-2'
import HausSeries3 from '../components/svg/haus-series-link/haus-series-3'
import { OutlineButton, theme } from '../util/style'
import BlogContainer from '../components/blog-page/PageBlog/BlogContainer'
import BlogCopyRow from '../components/blog-page/PageBlog/BlogCopyRow'
import BlogRelated from '../components/blog-page/PageBlog/BlogRelated'

const mapDispatchToProps = (dispatch) => ({})
const ogImages = [``]

const Page = ({ dispatch }) => {
  const isClosed = false

  const registerForm = {
    category: 'Open Haus Pre-registration',
    title: 'Pre-register for Open Haus',
    redirect: '/open-haus-thank-you',
    form: 'open-haus',
  }
  const subscribeForm = {
    category: 'Open Haus Subscribe Form',
    title: 'Open Haus Subscribe Form',
    redirect: '/open-haus-thank-you',
    form: 'open-haus-subscribe',
  }
  const handleClick = () => {
    dispatch(setLightboxForm(isClosed ? subscribeForm : registerForm))
  }

  return (
    <>
      <SEO
        title="Haus Series"
        description="Start with our Haus Series, a selection of plans drawn from our experience of what makes a haus, a home."
        images={ogImages}
      />
      <Row>
        <PageHeading mt={['35px', '85px']} as="h1" px={2} mb={['38px', '50px']}>
          Design ideas culminate in our Haus Series – a starting point for
          conversation and inspiration that can be shaped into your home.
        </PageHeading>
        <HouseSeriesRow mb={['38px', '76px']} noCopy>
          <HouseSeriesRowLinkItem
            heading="Haus Series"
            number="01"
            textColor="#3957A5"
            bgColor="#B3DFFF"
            link={'/haus-series-1'}
            svg={<HausSeriesLink1Svg />}
          />
          <HouseSeriesRowLinkItem
            heading="Haus Series"
            number="02"
            textColor="#FF634D"
            bgColor="#FFCDB2"
            link={'/haus-series-2'}
            svg={<HausSeries2 />}
          />
          <HouseSeriesRowLinkItem
            heading="Haus Series"
            number="03"
            textColor="#3E6F42"
            bgColor="#C4D39B"
            link="/haus-series-3"
            svg={<HausSeries3 />}
          />
          <HouseSeriesRowLinkItem
            heading={
              <>
                Collection <br />
                <b>Courtyards</b>
              </>
            }
            number={<Box style={{ opacity: 0 }}>.</Box>}
            textColor="#4B4B4B"
            bgColor="#CDCDC8"
            link="/courtyards"
          />
        </HouseSeriesRow>
        <BlogContainer>
          <BlogCopyRow
            data={{
              images: [
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-02-15-photo-tarragindi-1110214.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-02-22-photo-flower-1110741.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100748.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-photo-bardon-AM-2-2.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010433.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-05-23-photo-olive-1150570.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-02-22-photo-flower-1110878.jpg',
                  },
                },
              ],

              copyHtml: (
                <div>
                  <p>
                    Every architect-designed plan in our series draws from our
                    experience in Southeast Queensland. Each design is based on
                    common block types and aims to enhance living in the
                    subtropics. We are guided by a response to your site and
                    apply architectural principles to maximise light, breezes,
                    privacy, and views.
                  </p>
                  <p>
                    Since 2009, we’ve created homes for many great clients in
                    some of Australia's most amazing locations. Every
                    architect-designed home draws from our vast experience,
                    founded on our collaboration with revered architectural
                    practice Donovan Hill. Our design ideas culminate hear as a
                    starting point for conversation and inspiration that can be
                    shaped into your home.
                  </p>
                  <OutlineButton>View Gallery</OutlineButton>
                </div>
              ),
            }}
          />
        </BlogContainer>
        <BlogRelated
          data={{
            relatedPosts: [
              {
                slug: 'top-sales-results-in-new-farm-and-tarragindi',
                title: 'Top sales results in New Farm and Tarragindi',
                category: ['Noteworthy'],
                thumbnail: {
                  file: {
                    path: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100859.jpg',
                  },
                },
              },
              {
                title: 'Making more neighbourhood memories',
                slug: 'silex-haus-a-legacy-of-memories',
                category: ['Owners Club'],
                thumbnail: {
                  file: {
                    path: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010415.jpg',
                  },
                },
              },
            ],
          }}
        />
      </Row>
    </>
  )
}

export default connect(mapDispatchToProps)(Page)

const PageHeading = styled(Box)`
  font-weight: normal;
  font-family: ${theme.font.fontFamilySerifThin};
  font-size: 30px;
  line-height: 140%;
  width: 50%;

  @media (max-width: 800px) {
    width: 90%;
  }
`
