import React from 'react'

const IconRightArrow = ({ ...rest }) => {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x="0.5"
        y="0.5"
        width="43"
        height="43"
        rx="21.5"
        stroke="currentColor"
      />
      <path
        d="M21.4877 30.41H23.8877L33.0377 21.32L23.8877 12.2H21.4877L29.7377 20.39H11.5877V22.25H29.7377L21.4877 30.41Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconRightArrow
